var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { disabled: _vm.disabled, persistent: "", width: "50%" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on: dialog, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        disabled: _vm.disabled,
                        id: "open-thumbnail-modal",
                        color: "primary",
                        "e2e-space-upload": "",
                      },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  { ...dialog }
                ),
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-upload")]),
                  _vm._v(" Upload "),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("File upload")]),
          _c(
            "v-card-text",
            [
              _c(
                "v-tabs",
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c("v-tab", [_vm._v("Local device")]),
                  _c("v-tab", [_vm._v("Web Address (URL)")]),
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-card",
                        { staticClass: "mt-4", attrs: { flat: "" } },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c("uppy-upload", {
                                attrs: {
                                  endpoint: _vm.endpoint,
                                  reset: _vm.dialog,
                                },
                                on: {
                                  uploadComplete: function ($event) {
                                    _vm.dialog = false
                                  },
                                  uploading: _vm.uploading,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-card",
                        { staticClass: "mt-4", attrs: { flat: "" } },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c("url-upload", {
                                attrs: { reset: _vm.dialog },
                                on: {
                                  uploadComplete: function ($event) {
                                    _vm.dialog = false
                                  },
                                  uploading: _vm.uploading,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "", disabled: _vm.loading },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }